<template>
  <div class="st-slider" data-t="st-slider">
    <div
      ref="tabsContentRef"
      class="st-slider-content"
      :class="{ 'no-scroll': !props.scrollable }"
    >
      <component :is="listTag" class="st-slider-items">
        <slot />
      </component>
    </div>
    <StButton
      v-if="isVisibleArrows && !arrivedState.left"
      class="st-slider-arrow left"
      icon="chevron-left"
      size="s"
      type="gray"
      @click="scrollLeft"
    />
    <StButton
      v-if="isVisibleArrows && !arrivedState.right"
      class="st-slider-arrow right"
      icon="chevron-right"
      type="gray"
      size="s"
      @click="scrollRight"
    />
  </div>
</template>

<script lang="ts" setup>
import type { SliderItemEmitSelectedParams, SliderItemsApi } from './types'

const emit = defineEmits<{
  (e: 'select', { selectedItemIds, itemId }: SliderItemEmitSelectedParams): void
}>()

interface Props {
  /** Выбор нескольких табов */
  multiselect?: boolean
  /** Список индексов выбранных табов, если нужен внешний контроль */
  selectedIds?: (string | number)[]
  /** Прокрутка при нехватке горизонтального пространства */
  scrollable?: boolean
  /** Количество пикселей, на которое будет прокручиваться список */
  scrollStep?: number
  itemsGap?: number
  listTag?: 'div' | 'ul'
  arrowSize?: number
  arrowOffset?: number
  arrowOffsetVertical?: number
  disableArrows?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  multiselect: false,
  selectedIds: () => [],
  scrollable: true,
  scrollStep: 100,
  itemsGap: 8,
  listTag: 'div',
  arrowSize: 26,
  arrowOffset: 8,
  arrowOffsetVertical: 30,
})

const items = ref<HTMLElement[]>([])
const selectedItems = ref<(string | number)[]>(props.selectedIds)

watch(
  () => props.selectedIds,
  (newIndexes) => {
    selectedItems.value = newIndexes
  },
  {
    deep: true,
  },
)

function registerItem(tab: HTMLElement) {
  items.value.push(tab)
}

function unregisterItem(tab: HTMLElement) {
  const index = items.value.indexOf(tab)

  if (index !== -1) {
    items.value.splice(index, 1)
  }
}

function emitSelect(itemId: number | string) {
  const emitParams: SliderItemEmitSelectedParams = {
    selectedItemIds: selectedItems.value,
    itemId,
  }

  emit('select', emitParams)
}

function selectTab(tabId: number | string) {
  selectedItems.value = [tabId]

  emitSelect(tabId)
}

function multiselectTab(tabId: number | string) {
  const isValueExists = selectedItems.value.includes(tabId)

  if (isValueExists) {
    const itemIndex = selectedItems.value.indexOf(tabId)
    selectedItems.value.splice(itemIndex, 1)
  } else {
    selectedItems.value.push(tabId)
  }

  emitSelect(tabId)
}

function selectItemHandler(tabId: number | string) {
  if (props.multiselect) {
    multiselectTab(tabId)

    return
  }

  selectTab(tabId)
}

const api: SliderItemsApi = {
  items,
  selectedItems,
  registerItem,
  unregisterItem,
  selectItemHandler,
}

provide('StSliderItemsApi', api)

// Scrolling
const tabsContentRef = ref<HTMLElement | null>(null)

const arrowOffsetX = computed<string>(() => `${-props.arrowOffset}px`)
const arrowOffsetY = computed<string>(() => `${-props.arrowOffsetVertical}px`)
const itemsGap = computed<string>(() => `${props.itemsGap}px`)
const arrowButtonsSize = computed<string>(() => `${props.arrowSize}px`)
const { x, arrivedState, isScrolling } = useScroll(tabsContentRef, {
  behavior: 'smooth',
})

function scrollLeft() {
  x.value -= props.scrollStep
}
function scrollRight() {
  x.value += props.scrollStep
}

function scrollToStart() {
  x.value = 0
}

const isVisibleArrows = computed(() => {
  if (props.disableArrows) return false

  return props.scrollable || isScrolling.value
})

defineExpose({
  scrollToStart,
})
</script>

<style scoped>
.st-slider {
  position: relative;

  .st-slider-content {
    scrollbar-width: none;

    overflow: scroll hidden;

    margin-left: calc(var(--spacing-050) * -1);
    padding-left: var(--spacing-050);

    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.no-scroll {
      overflow: hidden;
    }
  }

  .st-slider-items {
    display: flex;
    gap: v-bind(itemsGap);
    align-items: flex-start;

    width: fit-content;
    margin: 0;
    padding: 0;

    list-style: none;
  }

  .st-slider-item {
    cursor: pointer;
  }

  .st-slider-arrow {
    position: absolute;
    z-index: 1;
    top: calc(50% - v-bind(arrowButtonsSize) / 2 + v-bind(arrowOffsetY));

    display: flex;
    align-items: center;
    justify-content: center;

    width: v-bind(arrowButtonsSize);
    height: v-bind(arrowButtonsSize);

    &.left {
      left: v-bind(arrowOffsetX);
    }

    &.right {
      right: v-bind(arrowOffsetX);
    }
  }
}
</style>
